import React, { useState, useEffect } from "react";
import axios from "axios";
import localforage from "localforage";
import "../css/form.css";
import assemblyData from "../assemblyData.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Dashboard from "./Dashboard";

function BoothPramukhForm() {
  const [formData, setFormData] = useState({
    phoneNumber: "",
    callingStatus: "",
    phoneMappedCorrectly: "",
    correctName: "",
    gender: "",
    assembly: "",
    boothPramukh: "",
    boothNumber: "",
    boothCommittee: "",
    committeeMembers: "",
    preparationRating: "",
    oppositionParty: "",
    userId: "",
    userName: "",
  });

  const [step, setStep] = useState(1);
  const [assemblies, setAssemblies] = useState([]);
  const [booths, setBooths] = useState([]);
  const [userId, setUserId] = useState([]);

  useEffect(() => {
    localforage.getItem("ID").then((userId) => {
      if (userId) {
        setFormData((prevData) => ({ ...prevData, userId }));
        setUserId(userId);
      } else {
        alert("User ID not found");
      }
    });

    localforage.getItem("userName").then((userName) => {
      if (userName) {
        setFormData((prevData) => ({ ...prevData, userName }));
      } else {
        alert("User name not found");
      }
    });

    const uniqueAssemblies = [
      ...new Set(
        assemblyData
          .sort((a, b) => {
            const aNum = parseInt(a.constituency.split("-")[0]);
            const bNum = parseInt(b.constituency.split("-")[0]);
            return aNum - bNum;
          })
          .map((item) => item.constituency)
      ),
    ];
    setAssemblies(uniqueAssemblies);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    switch (step) {
      case 1:
        if (!formData.phoneNumber) {
          alert("Please enter the Booth Pramukh's phone number.");
          return;
        }
        setStep(step + 1);
        break;
      case 2:
        if (!formData.callingStatus) {
          alert("Please select if the phone number is working.");
          return;
        }
        if (formData.callingStatus === "Answered") {
          setStep(step + 1);
        } else {
          submitForm();
        }
        break;
      case 3:
        if (!formData.phoneMappedCorrectly) {
          alert("Please answer whether the phone number is mapped correctly.");
          return;
        }
        if (formData.phoneMappedCorrectly === "No") {
          setStep(step + 1);
        } else {
          setStep(step + 2);
        }
        break;
      case 4:
        if (!formData.correctName) {
          alert("Please enter the correct name.");
          return;
        }
        setStep(step + 1);
        break;
      case 5:
        if (!formData.gender) {
          alert("Please select a gender.");
          return;
        }
        setStep(step + 1);
        break;
      case 6:
        if (!formData.assembly) {
          alert("Please select an assembly.");
          return;
        }
        setStep(step + 1);
        break;
      case 7:
        if (!formData.boothPramukh) {
          alert("Please answer if this person is a Booth Pramukh.");
          return;
        }
        if (formData.boothPramukh === "Yes") {
          setStep(step + 1);
        } else {
          setStep(11); // Skip to question 11 if the answer is No
        }
        break;
      case 8:
        if (!formData.boothNumber) {
          alert("Please enter the booth number.");
          return;
        }
        setStep(step + 1);
        break;
      case 9:
        if (!formData.boothCommittee) {
          alert("Please answer if there is a Booth Committee.");
          return;
        }
        if (formData.boothCommittee === "Yes") {
          setStep(step + 1);
        } else {
          setStep(11);
        }
        break;
      case 10:
        if (!formData.committeeMembers) {
          alert("Please enter the number of Booth Committee members.");
          return;
        }
        setStep(step + 1);
        break;
      case 11:
        if (formData.preparationRating) {
          setStep(step + 1);
        } else {
          setStep(12);
        }
        break;
      case 12:
        if (formData.oppositionParty) {
          submitForm();
        } else {
          submitForm();
        }
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const submitForm = () => {
    axios
      .post("http://3.110.158.33:5000/api/boothform/submit", formData)
      .then(() => {
        alert("Form submitted successfully");
        setFormData({
          phoneNumber: "",
          callingStatus: "",
          phoneMappedCorrectly: "",
          correctName: "",
          gender: "",
          assembly: "",
          boothPramukh: "",
          boothNumber: "",
          boothCommittee: "",
          committeeMembers: "",
          preparationRating: "",
          oppositionParty: "",
          userId: formData.userId,
          userName: formData.userName,
        });
        setStep(1);
      })
      .catch((error) => alert("Error submitting boothform"));
  };

  const handleAssemblyChange = (e) => {
    const selectedAssembly = e.target.value;
    setFormData({ ...formData, assembly: selectedAssembly });

    const filteredBooths = assemblyData
      .filter((item) => item.constituency === selectedAssembly)
      .map((item) => item.booth);
    setBooths(filteredBooths);
  };

  return (
    <div>
      <Dashboard />
      <div className="main-container">
        <h1 className="header-main">Booth Pramukh Survey</h1>
        <div className="form-container">
          <form onSubmit={submitForm}>
            {step === 1 && (
              <div className="form-group">
                <label>
                  Phone Number of the Booth Pramukh (Copy paste from the
                  Dashboards)
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                  style={{ marginLeft: "0px" }}
                />
                <button type="button" onClick={handleNext}>
                  Next
                </button>
              </div>
            )}
            {step === 2 && (
              <div className="form-group">
                <label>Is the Phone Number working?</label>
                <select name="callingStatus" onChange={handleChange} required>
                  <option value="">Select Status</option>
                  <option value="Answered">Answered</option>
                  <option value="Invalid Number">Invalid Number</option>
                  <option value="Not Answered">Not Answered</option>
                  <option value="Not Interested">Not Interested</option>
                  <option value="Switch Off">Switch Off</option>
                </select>
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 3 && (
              <div className="form-group">
                <label>Is the phone no. correctly mapped with the name?</label>
                <input
                  type="radio"
                  name="phoneMappedCorrectly"
                  value="Yes"
                  onChange={handleChange}
                  required
                />{" "}
                Yes
                <input
                  type="radio"
                  name="phoneMappedCorrectly"
                  value="No"
                  onChange={handleChange}
                  required
                />{" "}
                No
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 4 && (
              <div className="form-group">
                <label>What is his/her correct name?</label>
                <input
                  type="text"
                  name="correctName"
                  value={formData.correctName}
                  onChange={handleChange}
                  required
                  style={{ marginLeft: "0px" }}
                />
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 5 && (
              <div className="form-group">
                <label>What is his/her gender?</label>
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  onChange={handleChange}
                  required
                />{" "}
                Male
                <input
                  type="radio"
                  name="gender"
                  value="Female"
                  onChange={handleChange}
                  required
                />{" "}
                Female
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 6 && (
              <div className="form-group">
                <label>Select Assembly</label>
                <select
                  name="assembly"
                  value={formData.assembly}
                  onChange={handleAssemblyChange}
                  required
                >
                  <option value="">Select Assembly</option>
                  {assemblies.map((assembly, index) => (
                    <option key={index} value={assembly}>
                      {assembly}
                    </option>
                  ))}
                </select>
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}

            {step === 7 && (
              <div className="form-group">
                <label>Is this person a Booth Pramukh?</label>
                <input
                  type="radio"
                  name="boothPramukh"
                  value="Yes"
                  onChange={handleChange}
                  required
                />{" "}
                Yes
                <input
                  type="radio"
                  name="boothPramukh"
                  value="No"
                  onChange={handleChange}
                  required
                />{" "}
                No
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}

            {step === 8 && formData.boothPramukh === "Yes" && (
              <div className="form-group">
                <label>What is the booth number he/she overlooks?</label>
                <input
                  type="text"
                  name="boothNumber"
                  value={formData.boothNumber}
                  onChange={handleChange}
                  required
                  style={{ marginLeft: "0px" }}
                />
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}

            {step === 9 && formData.boothPramukh === "Yes" && (
              <div className="form-group">
                <label>Is there a Booth Committee?</label>
                <input
                  type="radio"
                  name="boothCommittee"
                  value="Yes"
                  onChange={handleChange}
                  required
                />{" "}
                Yes
                <input
                  type="radio"
                  name="boothCommittee"
                  value="No"
                  onChange={handleChange}
                  required
                />{" "}
                No
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}

            {step === 10 && formData.boothCommittee === "Yes" && (
              <div className="form-group">
                <label>How many Booth Committee members are there?</label>
                <input
                  type="number"
                  name="committeeMembers"
                  value={formData.committeeMembers}
                  onChange={handleChange}
                  required
                  style={{ marginLeft: "0px" }}
                />
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}

            {step === 11 && (
              <div className="form-group">
                <label>
                  Rate the Preparation of this Booth (1 to 10 Where 1 being Very
                  Poor and Excellent)
                </label>
                <input
                  type="number"
                  name="preparationRating"
                  value={formData.preparationRating}
                  onChange={handleChange}
                  min="1"
                  max="10"
                  style={{ marginLeft: "0px" }}
                />
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}

            {step === 12 && (
              <div className="form-group">
                <label>Which opposition party is working in this area?</label>
                <select
                  name="oppositionParty"
                  value={formData.oppositionParty}
                  onChange={handleChange}
                  style={{ marginLeft: "0px" }}
                  required
                >
                  <option value="">Select Opposition Party</option>
                  <option value="BJP">BJP</option>
                  <option value="INC">INC</option>
                  <option value="NCP(AP)">NCP(AP)</option>
                  <option value="NCP(SP)">NCP(SP)</option>
                  <option value="UBT">UBT</option>
                  <option value="SHS">SHS</option>
                  <option value="None">None</option>
                  <option value="Others">Others</option>
                </select>
                <button type="button" onClick={submitForm}>
                  Submit
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default BoothPramukhForm;
