import React, { useState, useEffect } from "react";
import axios from "axios";
import localforage from "localforage";
import "../css/form.css";
import assemblyData from "../assemblyData.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Dashboard from "./Dashboard";

function Form() {
  const [formData, setFormData] = useState({
    shivdootPhoneNumber: "",
    callingStatus: "",
    phoneMappedCorrectly: "",
    correctName: "",
    gender: "",
    assembly: "",
    boothNumber: "",
    associatedWithShivSena: "",
    associationDetails: "",
    memberDuration: "",
    awareOfRegistration: "",
    userId: "",
    userName: "",
  });

  const [step, setStep] = useState(1);
  const [assemblies, setAssemblies] = useState([]);
  const [booths, setBooths] = useState([]);
  const [userId, setUserId] = useState([]);

  useEffect(() => {
    localforage.getItem("ID").then((userId) => {
      if (userId) {
        setFormData((prevData) => ({ ...prevData, userId }));
        setUserId(userId);
      } else {
        alert("User ID not found");
      }
    });

    localforage.getItem("userName").then((userName) => {
      if (userName) {
        setFormData((prevData) => ({ ...prevData, userName }));
      } else {
        alert("User name not found");
      }
    });

    const uniqueAssemblies = [
      ...new Set(
        assemblyData
          .sort((a, b) => {
            const aNum = parseInt(a.constituency.split("-")[0]);
            const bNum = parseInt(b.constituency.split("-")[0]);
            return aNum - bNum;
          })
          .map((item) => item.constituency)
      ),
    ];
    setAssemblies(uniqueAssemblies);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    switch (step) {
      case 1:
        if (!formData.shivdootPhoneNumber) {
          alert("Please enter the Shivdoot phone number.");
          return;
        }
        setStep(step + 1);
        break;
      case 2:
        if (!formData.callingStatus) {
          alert("Please select the calling status.");
          return;
        }
        if (formData.callingStatus === "Answered") {
          setStep(step + 1);
        } else {
          axios
            .post("http://3.110.158.33:5000/api/form/submit", formData)
            .then(() => {
              alert("Form submitted successfully");
              setFormData({
                shivdootPhoneNumber: "",
                callingStatus: "",
                phoneMappedCorrectly: "",
                correctName: "",
                gender: "",
                assembly: "",
                boothNumber: "",
                associatedWithShivSena: "",
                associationDetails: "",
                memberDuration: "",
                awareOfRegistration: "",
                userId: formData.userId,
                userName: formData.userName,
              });
              setStep(1);
            })
            .catch((error) => alert("Error submitting form"));
        }
        break;
      case 3:
        if (!formData.phoneMappedCorrectly) {
          alert("Please answer whether the phone number is correctly mapped.");
          return;
        }
        if (formData.phoneMappedCorrectly === "No") {
          setStep(step + 1);
        } else {
          setStep(step + 2);
        }
        break;
      case 4:
        if (!formData.correctName) {
          alert("Please enter the correct name.");
          return;
        }
        setStep(step + 1);
        break;
      case 5:
        if (!formData.gender) {
          alert("Please select a gender.");
          return;
        }
        setStep(step + 1);
        break;
      case 6:
        if (!formData.assembly) {
          alert("Please select an assembly.");
          return;
        }
        setStep(step + 1);
        break;
      case 7:
        setStep(step + 1);
        break;
      case 8:
        if (!formData.associatedWithShivSena) {
          alert(
            "Please answer whether the person is associated with Shiv Sena."
          );
          return;
        }
        if (formData.associatedWithShivSena === "Yes") {
          setStep(step + 1);
        } else {
          setStep(step + 2);
        }
        break;
      case 9:
        if (!formData.associationDetails) {
          alert("Please select how the person is associated with Shiv Sena.");
          return;
        }
        setStep(step + 1);
        break;
      case 10:
        if (!formData.awareOfRegistration) {
          alert(
            "Please answer whether the person is aware of the registration."
          );
          return;
        }
        setStep(step + 1);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("http://3.110.158.33:5000/api/form/submit", formData)
      .then((response) => {
        alert("Form submitted successfully");
        setFormData({
          shivdootPhoneNumber: "",
          callingStatus: "",
          phoneMappedCorrectly: "",
          correctName: "",
          gender: "",
          assembly: "",
          boothNumber: "",
          associatedWithShivSena: "",
          associationDetails: "",
          memberDuration: "",
          awareOfRegistration: "",
          userId: formData.userId,
          userName: formData.userName,
        });
        setStep(1);
      })
      .catch((error) => alert("Error submitting form"));
  };

  const handleAssemblyChange = (e) => {
    const selectedAssembly = e.target.value;
    setFormData({ ...formData, assembly: selectedAssembly });

    const filteredBooths = assemblyData
      .filter((item) => item.constituency === selectedAssembly)
      .map((item) => item.booth);
    setBooths(filteredBooths);
  };

  return (
    <div>
      <Dashboard />
      <div className="main-container">
        <h1 className="header-main">Shivdoot Registration</h1>
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            {step === 1 && (
              <div className="form-group">
                <label>
                  Shivdoot Phone Number (Copy paste from the Dashboards)
                </label>
                <input
                  type="text"
                  name="shivdootPhoneNumber"
                  value={formData.shivdootPhoneNumber}
                  onChange={handleChange}
                  required
                  style={{ margin: "0px" }}
                />
                <button type="button" onClick={handleNext}>
                  Next
                </button>
              </div>
            )}
            {step === 2 && (
              <div className="form-group">
                <label>Calling Status</label>
                <select name="callingStatus" onChange={handleChange} required>
                  <option value="">Select Status</option>
                  <option value="Answered">Answered</option>
                  <option value="Invalid number">Invalid number</option>
                  <option value="Not answered">Not answered</option>
                  <option value="Switch off">Switch off</option>
                  <option value="Not interested">Not interested</option>
                </select>
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 3 && (
              <div className="form-group">
                <label>Is the phone no. mapped correctly?</label>
                <input
                  type="radio"
                  name="phoneMappedCorrectly"
                  value="Yes"
                  onChange={handleChange}
                  required
                />{" "}
                Yes
                <input
                  type="radio"
                  name="phoneMappedCorrectly"
                  value="No"
                  onChange={handleChange}
                  required
                />{" "}
                No
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 4 && (
              <div className="form-group">
                <label>If yes, please write the correct name here</label>
                <input
                  type="text"
                  name="correctName"
                  value={formData.correctName}
                  onChange={handleChange}
                  required
                />
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 5 && (
              <div className="form-group">
                <label>Gender</label>
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  onChange={handleChange}
                  required
                />{" "}
                Male
                <input
                  type="radio"
                  name="gender"
                  value="Female"
                  onChange={handleChange}
                  required
                />{" "}
                Female
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 6 && (
              <div className="form-group">
                <label>Assembly (Choose from dropdown list)</label>
                <select
                  name="assembly"
                  value={formData.assembly}
                  onChange={handleAssemblyChange}
                  required
                >
                  <option value="">Select Assembly</option>
                  {assemblies.map((assembly, index) => (
                    <option key={index} value={assembly}>
                      {assembly}
                    </option>
                  ))}
                </select>
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 7 && (
              <div className="form-group">
                <label>Booth Number</label>
                <select
                  name="boothNumber"
                  value={formData.boothNumber}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Booth</option>
                  {booths.map((booth, index) => (
                    <option key={index} value={booth}>
                      {booth}
                    </option>
                  ))}
                </select>
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 8 && (
              <div className="form-group">
                <label>Is the person associated with Shiv Sena?</label>
                <input
                  type="radio"
                  name="associatedWithShivSena"
                  value="Yes"
                  onChange={handleChange}
                  required
                />{" "}
                Yes
                <input
                  type="radio"
                  name="associatedWithShivSena"
                  value="No"
                  onChange={handleChange}
                  required
                />{" "}
                No
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 9 && (
              <div className="form-group">
                <label>How is he/she associated with Shiv Sena?</label>
                <select
                  name="associationDetails"
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Association Type</option>
                  <option value="Shiv Sena cadre/leader">
                    Shiv Sena cadre/leader
                  </option>
                  <option value="Shivdoot">Shivdoot</option>
                  <option value="Shiv Sena supporter">
                    Shiv Sena supporter
                  </option>
                  <option value="Others">Others</option>
                </select>
                <button type="button" onClick={handleNext}>
                  Next
                </button>
              </div>
            )}
            {step === 10 && (
              <div className="form-group">
                <label>Are you aware of the Shivdoot Registration Drive?</label>
                <input
                  type="radio"
                  name="awareOfRegistration"
                  value="Yes"
                  onChange={handleChange}
                  required
                />{" "}
                Yes
                <input
                  type="radio"
                  name="awareOfRegistration"
                  value="No"
                  onChange={handleChange}
                  required
                />{" "}
                No
                <button type="submit">Submit</button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 0 && (
              <div className="form-end">
                <h3>Form Ended!!!!!</h3>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Form;
