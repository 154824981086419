import React, { useState, useEffect } from "react";
import axios from "axios";
import localforage from "localforage";
import "../css/datadisplay.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Dashboard from "./Dashboard";

const DataDisplay = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const getUserId = async () => {
      try {
        const id = await localforage.getItem("ID");
        if (id) {
          setUserId(id);
          fetchData(id);
        } else {
          setError("User ID not found");
          setLoading(false);
        }
      } catch (err) {
        setError("Error retrieving User ID");
        setLoading(false);
      }
    };

    const fetchData = async (id) => {
      try {
        const response = await axios.get(
          `http://3.110.158.33:5000/api/dataset/get-data/${id}/entries`
        );
        setData(response.data.entries); // Use response.data.entries as your API returns { entries: [...] }
        setLoading(false);
      } catch (err) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    getUserId();
  }, []);

  const handleStatusChange = (id, field, value) => {
    setData((prevData) =>
      prevData.map((item) =>
        item._id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const handleUpdate = async (id) => {
    const itemToUpdate = data.find((item) => item._id === id);
    if (itemToUpdate) {
      try {
        // Retrieve user details from localForage or context
        const userId = await localforage.getItem('ID');
        const userName = await localforage.getItem('userName');
        
        // Ensure both userId and userName are available
        if (!userId || !userName) {
          throw new Error('User details are missing');
        }
        
        await axios.put(
          `http://3.110.158.33:5000/api/dataset/update-calling/${id}/calling-status`,
          {
            status: itemToUpdate.callingStatus,
            formFilled: itemToUpdate.formFilled,
            userId: userId,        // Include userId
            userName: userName     // Include userName
          }
        );
        alert("Entry updated successfully!");
      } catch (err) {
        setError("Error updating entry");
      }
    }
  };

  const getFormFilledStyle = (formFilled) => {
    return formFilled === "Yes"
      ? { backgroundColor: "#d4edda" }
      : { backgroundColor: "#f8d7da" };
  };

  const getCallingStatusStyle = (callingStatus) => {
    switch (callingStatus) {
      case "Answered":
        return { backgroundColor: "#d4edda" };
      case "Invalid number":
        return { backgroundColor: "#fff3cd" };
      case "Not answered":
        return { backgroundColor: "#ffe5b4" };
      case "Switch off":
        return { backgroundColor: "#cce5ff" };
      case "Not interested":
        return { backgroundColor: "#FF7F7F" };
      default:
        return {};
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (data.length === 0) return <div>No data available</div>;

  return (
    <div>
      <Dashboard />
      <div className="data-display-container">
        <h1 style={{ textAlign: "center" }}>Booth Pramukh Data</h1>
        <table border="1" className="data-table">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Zone</th>
              <th>District</th>
              <th>AC Number</th>
              <th>AC Name</th>
              <th>Booth Number</th>
              <th>Booth Pramukh Name</th>
              <th>Booth Pramukh Contact Number</th>
              <th>Booth Pramukh Gender</th>
              <th>Calling Status</th>
              <th>Form Filled</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item._id}>
                <td>{index + 1}</td>
                <td>{item.zone}</td>
                <td>{item.district}</td>
                <td>{item.acNumber}</td>
                <td>{item.acName}</td>
                <td>{item.boothNumber}</td>
                <td>{item.boothPramukhName}</td>
                <td>{item.boothPramukhContactNumber}</td>
                <td>{item.boothPramukhGender}</td>

                <td style={getCallingStatusStyle(item.callingStatus)}>
                  <select
                    value={item.callingStatus}
                    onChange={(e) =>
                      handleStatusChange(
                        item._id,
                        "callingStatus",
                        e.target.value
                      )
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <option value="">Select Status</option>
                    <option value="Answered">Answered</option>
                    <option value="Invalid number">Invalid number</option>
                    <option value="Not answered">Not answered</option>
                    <option value="Switch off">Switch off</option>
                    <option value="Not interested">Not interested</option>
                  </select>
                  <a
                    onClick={() => handleUpdate(item._id)}
                    style={{
                      marginLeft: "5px",
                      fontSize: "12px",
                      padding: "2px 5px",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="font-pdf3"
                      size="2x"
                    />
                  </a>
                </td>
                <td style={getFormFilledStyle(item.formFilled)}>
                  <select
                    value={item.formFilled}
                    onChange={(e) =>
                      handleStatusChange(item._id, "formFilled", e.target.value)
                    }
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataDisplay;
