import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/formStats.css";
import Dashboard from "./Dashboard";

const FormStats = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [callingStatusStats, setCallingStatusStats] = useState({
    today: {},
    total: {},
  });
  const [callingStatusPerUser, setCallingStatusPerUser] = useState({
    today: {},
    total: {},
  });
  const [phoneWorkingStats, setPhoneWorkingStats] = useState({
    yes: 0,
    no: 0,
    total: 0,
  });
  const [sortOrder, setSortOrder] = useState({ field: null, order: "asc" });
  const [expandedUsers, setExpandedUsers] = useState({});
  const [sortConfig, setSortConfig] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const formResponse = await axios.get(
          "http://3.110.158.33:5000/api/boothform/forms/stats"
        );
        const callingStatusResponse = await axios.get(
          "http://3.110.158.33:5000/api/dataset/calling-status/stats"
        );
        const callingStatusPerUserResponse = await axios.get(
          "http://3.110.158.33:5000/api/dataset/calling-status/stats-per-user"
        );
        const phoneWorkingResponse = await axios.get(
          "http://3.110.158.33:5000/api/boothform/phone-working-stats"
        );

        setStats(formResponse.data.stats);
        setCallingStatusStats(callingStatusResponse.data);
        setCallingStatusPerUser(callingStatusPerUserResponse.data);
        setPhoneWorkingStats({
          yes: phoneWorkingResponse.data.totalYes,
          no: phoneWorkingResponse.data.totalNo,
          total: phoneWorkingResponse.data.overallTotal,
        });
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch stats");
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  const calculateTotal = (statuses) => {
    return (
      (statuses["Invalid number"] || 0) +
      (statuses["Not interested"] || 0) +
      (statuses["Not answered"] || 0) +
      (statuses["Switch off"] || 0) +
      (statuses["Answered"] || 0)
    );
  };

  const sortedData = (data) => {
    if (!sortConfig) return data;

    const sorted = [...data].sort((a, b) => {
      const aValue = calculateTotal(a[1].statuses);
      const bValue = calculateTotal(b[1].statuses);

      if (sortConfig.key === "Total") {
        return sortConfig.direction === "ascending"
          ? aValue - bValue
          : bValue - aValue;
      }

      const aColumnValue = a[1].statuses[sortConfig.key] || 0;
      const bColumnValue = b[1].statuses[sortConfig.key] || 0;

      return sortConfig.direction === "ascending"
        ? aColumnValue - bColumnValue
        : bColumnValue - aColumnValue;
    });

    return sorted;
  };

  const handleSort = (field) => {
    const order =
      sortOrder.field === field && sortOrder.order === "asc" ? "desc" : "asc";
    setSortOrder({ field, order });
    const sortedStats = [...stats].sort((a, b) => {
      if (a[field] < b[field]) return order === "asc" ? -1 : 1;
      if (a[field] > b[field]) return order === "asc" ? 1 : -1;
      return 0;
    });
    setStats(sortedStats);
  };

  const handleSort2 = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const totalFormsFilledToday = stats.reduce(
    (acc, stat) => acc + stat.todayCount,
    0
  );
  const totalFormsFilledOverall = stats.reduce(
    (acc, stat) => acc + stat.overallCount,
    0
  );

  const calculateTotalCalled = (data) => {
    return Object.entries(data)
      .filter(([status]) => status !== "" && status !== "No Status")
      .reduce((acc, [, count]) => acc + count, 0);
  };

  const totalCalledToday = calculateTotalCalled(callingStatusStats.today);
  const totalCalledOverall = calculateTotalCalled(callingStatusStats.total);

  const noStatusToday = callingStatusStats.today["No Status"] || 0;
  const noStatusOverall = callingStatusStats.total["No Status"] || 0;

  const toggleUserView = (userId) => {
    setExpandedUsers((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
  };

  if (loading) return <p className="form-stats-message">Loading...</p>;
  if (error) return <p className="form-stats-message">{error}</p>;

  return (
    <div>
      <Dashboard />
      <div className="form-stats-container">
        <h1 className="form-stats-heading">Form Leaderboard</h1>
        <table className="form-stats-table">
          <thead>
            <tr>
              <th onClick={() => handleSort("userName")}>
                User Name{" "}
                {sortOrder.field === "userName" &&
                  (sortOrder.order === "asc" ? "▲" : "▼")}
              </th>
              <th onClick={() => handleSort("email")}>
                Email{" "}
                {sortOrder.field === "email" &&
                  (sortOrder.order === "asc" ? "▲" : "▼")}
              </th>
              <th onClick={() => handleSort("todayCount")}>
                Forms Filled Today{" "}
                {sortOrder.field === "todayCount" &&
                  (sortOrder.order === "asc" ? "▲" : "▼")}
              </th>
              <th onClick={() => handleSort("overallCount")}>
                Total Forms Filled{" "}
                {sortOrder.field === "overallCount" &&
                  (sortOrder.order === "asc" ? "▲" : "▼")}
              </th>
            </tr>
          </thead>
          <tbody>
            {stats.map((stat) => (
              <tr key={stat.userId}>
                <td>{stat.userName}</td>
                <td>{stat.email}</td>
                <td>{stat.todayCount}</td>
                <td>{stat.overallCount}</td>
              </tr>
            ))}
            <tr className="form-stats-total">
              <td colSpan="2">Total</td>
              <td>{totalFormsFilledToday}</td>
              <td>{totalFormsFilledOverall}</td>
            </tr>
          </tbody>
        </table>

        <h2 className="form-stats-heading">Cumulative Calling Snapshot</h2>
        <table className="form-stats-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Calls Connected</td>
              <td>{phoneWorkingStats.yes}</td>
            </tr>
            <tr>
              <td>Calls Not Connected</td>
              <td>{phoneWorkingStats.no}</td>
            </tr>
            <tr className="form-stats-total">
              <td> Total Calls Made</td>
              <td>{phoneWorkingStats.total}</td>
            </tr>
          </tbody>
        </table>

        <h2 className="form-stats-heading">Today's Calling Status Breakdown</h2>
        <table className="form-stats-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(callingStatusStats.today).map(([status, count]) => (
              <tr key={status}>
                <td>{status}</td>
                <td>{count}</td>
              </tr>
            ))}
            <tr className="form-stats-total">
              <td>Total Called</td>
              <td>{totalCalledToday}</td>
            </tr>
            <tr className="form-stats-total">
              <td>No Status</td>
              <td>{noStatusToday}</td>
            </tr>
          </tbody>
        </table>

        <h2 className="form-stats-heading">Total Calling Status Breakdown</h2>
        <table className="form-stats-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(callingStatusStats.total).map(([status, count]) => (
              <tr key={status}>
                <td>{status}</td>
                <td>{count}</td>
              </tr>
            ))}
            <tr className="form-stats-total">
              <td>Total Called</td>
              <td>{totalCalledOverall}</td>
            </tr>
            <tr className="form-stats-total">
              <td>No Status</td>
              <td>{noStatusOverall}</td>
            </tr>
          </tbody>
        </table>

        <h2 className="form-stats-heading">Today's Calling Status Per User</h2>
        <table className="form-stats-table">
          <thead>
            <tr>
              <th>User</th>
              <th onClick={() => handleSort2("Answered")}>Answered</th>
              <th onClick={() => handleSort2("Invalid number")}>
                Invalid number
              </th>
              <th onClick={() => handleSort2("Not interested")}>
                Not interested
              </th>
              <th onClick={() => handleSort2("Not answered")}>Not answered</th>
              <th onClick={() => handleSort2("Switch off")}>Switch off</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {sortedData(Object.entries(callingStatusPerUser.today)).map(
              ([userId, userData]) => (
                <React.Fragment key={userId}>
                  <tr onClick={() => toggleUserView(userId)}>
                    <td>{userData.name}</td>
                    <td>{userData.statuses["Answered"] || 0}</td>
                    <td>{userData.statuses["Invalid number"] || 0}</td>
                    <td>{userData.statuses["Not interested"] || 0}</td>
                    <td>{userData.statuses["Not answered"] || 0}</td>
                    <td>{userData.statuses["Switch off"] || 0}</td>
                    <td>{calculateTotal(userData.statuses)}</td>
                  </tr>
                  {expandedUsers[userId] && (
                    <tr className="expanded-row">
                      <td colSpan="7">
                        <div className="expanded-content">
                          {Object.entries(userData.statuses).map(
                            ([status, count]) => (
                              <div key={status}>
                                {status}: {count}
                              </div>
                            )
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FormStats;
