import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import UpdateLogin from "./components/UpdateLogin";
import Register from "./components/Register";
import WelcomePage from "./components/WelcomePage";
import UpdateUser from "./components/UpdateUser";
import Form from "./components/Form";
import DataDisplay from "./components/DataDisplay";
import FormStats from "./components/FormStats";
import Report from "./components/Reports";
import BoothPramukhForm from "./components/BoothPramukhForm";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/nWuRGm1GvLXyCmQ6TbxqfQ7YasvDlY8z87TxUHrX0HUhX0Pxa9" element={<Register />} />
        <Route exact path="/welcome" element={<WelcomePage />} />
        <Route exact path="/update" element={<UpdateLogin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/update-user" element={<UpdateUser />} />
        {/* <Route path="/shivdoot-form" element={<Form />} /> */}
        <Route path="/booth-form" element={<BoothPramukhForm />} />
        <Route path="/data-display" element={<DataDisplay />} />
        <Route path="/form-stats" element={<FormStats />} />
        <Route path="/reports" element={<Report />} />
      </Routes>
    </Router>
  );
};

export default App;
