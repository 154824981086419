import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import "../css/reports.css";
import ChartDataLabels from "chartjs-plugin-datalabels";

const CumulativeCallingSnapshot = () => {
  const [data, setData] = useState({
    totalCallsMade: 0,
    callsConnected: 0,
    callsNotConnected: 0,
    associatedWithShivSena: 0,
    totalShivdoots: 0,
  });

  const [phoneStats, setPhoneStats] = useState({ totalYes: 0, totalNo: 0 });
  const [genderStats, setGenderStats] = useState({
    maleCount: 0,
    femaleCount: 0,
    totalShivdoots: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetching phone working stats
        const phoneStatsResponse = await axios.get(
          "http://3.110.158.33:5000/api/form/phone-working-stats"
        );
        const { totalYes, totalNo, overallTotal } = phoneStatsResponse.data;

        // Fetching associated with Shiv Sena count
        const associatedWithShivSenaResponse = await axios.get(
          "http://3.110.158.33:5000/api/form/associated-with-shivsena"
        );
        const associatedWithShivSena =
          associatedWithShivSenaResponse.data.associatedCount;

        // Fetching total Shivdoots count
        const totalShivdootsResponse = await axios.get(
          "http://3.110.158.33:5000/api/form/total-shivdoots"
        );
        const totalShivdoots = totalShivdootsResponse.data.totalShivdoots;

        // Fetching gender distribution data
        const genderStatsResponse = await axios.get(
          "http://3.110.158.33:5000/api/form/gender-distribution"
        );
        const { maleCount, femaleCount } = genderStatsResponse.data;

        // Setting the state with fetched data
        setData({
          totalCallsMade: overallTotal,
          callsConnected: totalYes,
          callsNotConnected: totalNo,
          associatedWithShivSena,
          totalShivdoots,
        });

        // Setting phone stats data for pie chart
        setPhoneStats({ totalYes, totalNo });

        // Setting gender stats data for pie chart
        setGenderStats({ maleCount, femaleCount, totalShivdoots });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Pie chart data for phone validity
  const phoneValidityChartData = {
    labels: ["Yes", "No"],
    datasets: [
      {
        label: "Phone Working",
        data: [phoneStats.totalYes, phoneStats.totalNo],
        backgroundColor: ["#FFA500", "#555555"],
        borderColor: ["#FFFFFF", "#FFFFFF"],
        borderWidth: 1,
      },
    ],
  };

  // Pie chart data for Shivdoots percentage
  const shivdootsChartData = {
    labels: ["Shivdoots", "Non-Shivdoots"],
    datasets: [
      {
        label: "% of Shivdoots from Total Dataset",
        data: [data.totalShivdoots, data.totalCallsMade - data.totalShivdoots],
        backgroundColor: ["#FFA500", "#555555"],
        borderColor: ["#FFFFFF", "#FFFFFF"],
        borderWidth: 1,
      },
    ],
  };

  // Pie chart data for gender distribution
  const genderDistributionChartData = {
    labels: ["Male", "Female"],
    datasets: [
      {
        label: "Gender Distribution",
        data: [genderStats.maleCount, genderStats.femaleCount],
        backgroundColor: ["#FFA500", "#555555"],
        borderColor: ["#FFFFFF", "#FFFFFF"],
        borderWidth: 1,
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: {
        formatter: (value, context) => {
          const total = context.chart._metasets[context.datasetIndex].total;
          const percentage = ((value / total) * 100).toFixed(1) + "%";
          return percentage;
        },
        color: "#fff",
        font: {
          weight: "bold",
          size: 16,
        },
      },
    },
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h2>Cumulative Calling Snapshot</h2>
      <table
        border="1"
        style={{
          width: "50%",
          margin: "20px auto",
          textAlign: "center",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr style={{ backgroundColor: "#9bc1f0", fontWeight: "bold" }}>
            <th colSpan="2">Cumulative Calling Snapshot</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total Calls Made</td>
            <td>{data.totalCallsMade}</td>
          </tr>
          <tr>
            <td>Calls Connected</td>
            <td>{data.callsConnected}</td>
          </tr>
          <tr>
            <td>Calls Not Connected</td>
            <td>{data.callsNotConnected}</td>
          </tr>
          <tr>
            <td>Associated with the Shiv Sena</td>
            <td>
              {data.associatedWithShivSena} (
              {(
                (data.associatedWithShivSena / data.totalCallsMade) *
                100
              ).toFixed(2)}
              %)
            </td>
          </tr>
          <tr>
            <td>Total no. of Shivdoots</td>
            <td>
              {data.totalShivdoots} (
              {((data.totalShivdoots / data.totalCallsMade) * 100).toFixed(2)}%)
            </td>
          </tr>
        </tbody>
      </table>
      <div className="main-pies">
        <div style={{ width: "25%", margin: "0 auto" }}>
          <h3>Validity of Phone Numbers</h3>
          <Pie
            data={phoneValidityChartData}
            options={pieChartOptions}
            plugins={[ChartDataLabels]}
          />
        </div>

        <div style={{ width: "25%", margin: "20px auto" }}>
          <h3>Gender Distribution of Shivdoots</h3>
          <Pie
            data={genderDistributionChartData}
            options={pieChartOptions}
            plugins={[ChartDataLabels]}
          />
        </div>
      </div>
      <h3>% of Shivdoots from Total Dataset</h3>
      <div style={{ width: "25%", margin: "20px auto" }}>
        <Pie
          data={shivdootsChartData}
          options={pieChartOptions}
          plugins={[ChartDataLabels]}
        />
      </div>
    </div>
  );
};

export default CumulativeCallingSnapshot;
