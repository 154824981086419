import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFile } from "@fortawesome/free-solid-svg-icons";

function DownloadCSVDataset() {
  const handleDownload = async () => {
    try {
      const response = await axios.get(
        "http://3.110.158.33:5000/api/form/export-csv-dataset",
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "callingDataset.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading the CSV file", error);
    }
  };

  return (
    <a onClick={handleDownload} className="menu-buttons">
      <FontAwesomeIcon icon={faDownload} className="font-pdf" size="3x" />
      Download Calling Data
    </a>
  );
}

export default DownloadCSVDataset;
