import React, { useEffect, useState } from "react";
import "../css/sidebar.css";
import { useNavigate } from "react-router-dom";
import localforage from "localforage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faFile,
  faFolder,
  faHome,
  faHouse,
  faPieChart,
  faSignOutAlt,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

const SideBar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const logout = async () => {
    await localforage.removeItem("token");
    await localforage.removeItem("ID");
    await localforage.removeItem("email");
    navigate("/");
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const storedRole = await localforage.getItem("role1");
        if (storedRole) {
          setRole(storedRole);
        } else {
          console.log("Role not found in localforage.");
        }
      } catch (error) {
        console.error("Error fetching role:", error);
      }
    };

    fetchUserRole();
  }, []);

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const storedEmail = await localforage.getItem("email");
        if (storedEmail) {
          setEmail(storedEmail);
        } else {
          console.log("Email not found in localforage.");
        }
      } catch (error) {
        console.error("Error fetching email:", error);
      }
    };
    fetchUserEmail();
  }, []);

  return (
    <div>
      <div
        className={`w3-sidebar w3-bar-block w3-card w3-animate-left ${
          sidebarOpen ? "open" : ""
        }`}
      >
        <div className="close-div">
          <button className="sidebar-close-button" onClick={closeSidebar}>
            <FontAwesomeIcon icon={faClose} className="font-pdf2" size="1x" />
          </button>
          <a href="/welcome" className="w3-bar-item w3-button">
            <div className="main-text-box">
              <FontAwesomeIcon icon={faHome} className="font-pdf2" size="1x" />
              Home
            </div>
          </a>
          <a href="/booth-form" className="w3-bar-item w3-button">
            <div className="main-text-box">
              <FontAwesomeIcon icon={faFile} className="font-pdf2" size="1x" />
              Booth Pramukh Form
            </div>
          </a>
          <a href="/data-display" className="w3-bar-item w3-button">
            <div className="main-text-box">
              <FontAwesomeIcon icon={faFolder} className="font-pdf2" size="1x" />
              Booth Data
            </div>
          </a>
          <a href="/form-stats" className="w3-bar-item w3-button">
            <div className="main-text-box">
              <FontAwesomeIcon icon={faPieChart} className="font-pdf2" size="1x" />
              Stats
            </div>
          </a>
          
          {role !== "mod" ? null : (
            <a href="/nWuRGm1GvLXyCmQ6TbxqfQ7YasvDlY8z87TxUHrX0HUhX0Pxa9" className="w3-bar-item w3-button">
              <div className="main-text-box">
                <FontAwesomeIcon
                  icon={faUserPlus}
                  className="font-pdf2"
                  size="1x"
                />
                Add User
              </div>
            </a>
          )}
         
          <a href="/" className="w3-bar-item w3-button" onClick={logout}>
            <div className="main-text-box">
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="font-pdf2"
                size="1x"
              />
              Logout
            </div>
          </a>
        </div>
      </div>

      <div id="main">
        <button id="openNav" className="sidebar-button" onClick={openSidebar}>
          &#9776;
        </button>
      </div>
    </div>
  );
};

export default SideBar;
