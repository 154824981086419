import React, { useState, useEffect } from "react";
import localforage from "localforage";
import "../css/welcomepage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faFolder,
  faPieChart,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Dashboard from "./Dashboard";
import DownloadCSVButton from "./DownloadCSVButton";
import DownloadCSVDataset from "./DownloadCSVDataset";

const WelcomePage = () => {
  const [userName, setUserName] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [email, setEmail] = useState("");

  const [role, setRole] = useState("");

  useEffect(() => {
    localforage.getItem("userName").then((name) => {
      if (name) {
        setUserName(name);
      }
    });

    localforage.getItem("roles").then((roles) => {
      if (roles) {
        setUserRoles(roles.map((role) => role.toUpperCase()));
      }
    });
  }, []);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const storedRole = await localforage.getItem("role1");
        if (storedRole) {
          setRole(storedRole);
        } else {
          console.log("Role not found in localforage.");
        }
      } catch (error) {
        console.error("Error fetching role:", error);
      }
    };

    fetchUserRole();
  }, []);

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const storedEmail = await localforage.getItem("email");
        if (storedEmail) {
          setEmail(storedEmail);
        } else {
          console.log("Email not found in localforage.");
        }
      } catch (error) {
        console.error("Error fetching email:", error);
      }
    };
    fetchUserEmail();
  }, []);

  return (
    <>
      <Dashboard />
      <div className="main-welcome">
        <h1 className="header">Welcome, {userName}!</h1>
      </div>
      <div className="buttons-container">
        <div className="buttons">
          <a href="/booth-form" className="menu-buttons">
            <FontAwesomeIcon icon={faFile} className="font-pdf" size="3x" />
            Booth Pramukh Form
          </a>
          <a href="/data-display" className="menu-buttons">
            <FontAwesomeIcon icon={faFolder} className="font-pdf" size="3x" />
            Booth Data
          </a>
          <a href="/form-stats" className="menu-buttons">
            <FontAwesomeIcon icon={faPieChart} className="font-pdf" size="3x" />
            Stats
          </a>
          {role !== "mod" ? null : (
            <a href="/nWuRGm1GvLXyCmQ6TbxqfQ7YasvDlY8z87TxUHrX0HUhX0Pxa9" className="menu-buttons">
                <FontAwesomeIcon
                  icon={faUserPlus}
                  className="font-pdf"
                  size="3x"
                />
                Add User
            </a>
          )}
          {role !== "mod" ? null : (
          <DownloadCSVButton />
          )}
          {role !== "mod" ? null : (
          <DownloadCSVDataset />
          )}
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
